import React from "react";
import theme from "theme";
import { Theme, Box, Text, Image } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"team"} />
		<Helmet>
			<title>
				SPhotonix — Revolutionizing Data Storage & Photonics
			</title>
			<meta name={"description"} content={"Web site created using quarkly.io"} />
			<meta property={"og:title"} content={"SPhotonix — Revolutionizing Data Storage & Photonics"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/66b79a6ce778710018d27b85/images/SPhotonix-fav32w.png?v=2024-09-23T14:28:13.820Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/66b79a6ce778710018d27b85/images/SPhotonix-fav152w.png?v=2024-09-23T14:28:06.690Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/66b79a6ce778710018d27b85/images/SPhotonix-fav152w.png?v=2024-09-23T14:28:06.690Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/66b79a6ce778710018d27b85/images/SPhotonix-fav152w.png?v=2024-09-23T14:28:06.690Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/66b79a6ce778710018d27b85/images/SPhotonix-fav152w.png?v=2024-09-23T14:28:06.690Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/66b79a6ce778710018d27b85/images/SPhotonix-fav270w.png?v=2024-09-23T14:27:58.132Z"} />
			<meta name={"msapplication-TileColor"} content={"#ffffff"} />
		</Helmet>
		<Components.Header position="fixed" right="0px" left="0px" z-index="1">
			<Override slot="linkBox7" color="--accentOrange" />
			<Override slot="linkBox14" display="none" />
			<Override slot="button" type="link" href="/memory-crystal">
				Order 5D Memory Crystal™
			</Override>
			<Override slot="text" font="600 27px Epilogue">
				SPhotonix
			</Override>
			<Override slot="text8" font="20px/24px Epilogue" />
			<Override slot="text15" font="20px/24px Epilogue">
				Technology
			</Override>
			<Override slot="text17" font="20px/24px Epilogue" sm-font="--bodyText17Medium">
				Contact
			</Override>
			<Override slot="image" height="50px" />
			<Override slot="linkBox8" href="/data-storage-innovation" />
		</Components.Header>
		<Box height="88px" sm-height="52px" />
		<Box
			width="100%"
			background="linear-gradient(0deg, #EEEEEE 0%, #EEEEEE 100%)"
			flex-direction="column"
			justify-content="flex-start"
			align-items="flex-start"
			grid-gap="40px"
			display="inline-flex"
			sm-padding="60px 16px 40px 16px"
			sm-height="min-content"
			padding="120px 16px 64px 16px"
		>
			{"  "}
			<Box
				align-self="stretch"
				flex-direction="column"
				justify-content="flex-start"
				align-items="center"
				grid-gap="28px"
				display="flex"
				sm-width="100%"
				sm-display="flex"
				sm-grid-row-gap="16px"
				sm-height="fit-content"
			>
				{"    "}
				<Text
					align-self="stretch"
					text-align="center"
					color="#080808"
					font="600 72px Epilogue"
					line-height="82px"
					word-wrap="break-word"
					sm-font="600 28px Epilogue"
					sm-line-height="32px"
					margin="0 0px 0 0px"
					sm-height="fit-content"
				>
					SPhotonix Leadership
				</Text>
				{"    "}
				<Text
					text-align="center"
					color="#212121"
					font="400 21px Epilogue"
					line-height="32px"
					word-wrap="break-word"
					margin="0 0px 0 0px"
					sm-font="400 17px/26px Epilogue"
					sm-width="100%"
					sm-height="min-content"
					max-width="747px"
				>
					Meet our team paving the way in optical fabrication and ultra-long-term data preservation. SPhotonix is pioneering the future of precision and ultrafast nanostructuring innovation.
				</Text>
				{"  "}
			</Box>
		</Box>
		<Box
			padding-left="190px"
			padding-right="190px"
			background="linear-gradient(0deg, #EEEEEE 0%, #EEEEEE 100%)"
			flex-direction="column"
			justify-content="flex-start"
			align-items="center"
			grid-gap="100px"
			display="flex"
			width="100%"
			padding="0px 16px 0px 16px"
			sm-display="flex"
			sm-grid-row-gap="60px"
		>
			<Box
				min-width="100px"
				min-height="100px"
				width="100%"
				align-items="center"
				justify-items="center"
				lg-grid-template-columns="1fr"
				max-width="1350px"
				margin="0px 0px 100px 0px"
				sm-margin="0px 0px 0px 0px"
				display="grid"
				lg-justify-content="center"
				flex-wrap="wrap"
				justify-content="center"
				grid-template-columns="repeat(auto-fit, minmax(450px, max-content))"
			>
				<Box
					width="450px"
					height="536px"
					padding="32px"
					border="1px #B1B1B1 solid"
					flex-direction="column"
					justify-content="flex-start"
					align-items="flex-start"
					grid-gap="32px"
					display="inline-flex"
					sm-width="100%"
					sm-align-items="center"
					sm-display="flex"
					sm-flex-direction="column"
					sm-justify-content="space-between"
					sm-flex-wrap="no-wrap"
					sm-height="min-content"
				>
					{"  "}
					<Image
						width="200px"
						height="200px"
						border-radius="9999px"
						src="https://uploads.quarkly.io/66b79a6ce778710018d27b85/images/ilya.webp?v=2024-10-03T22:10:01.144Z"
						srcSet="https://smartuploads.quarkly.io/66b79a6ce778710018d27b85/images/ilya.webp?v=2024-10-03T22%3A10%3A01.144Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/66b79a6ce778710018d27b85/images/ilya.webp?v=2024-10-03T22%3A10%3A01.144Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/66b79a6ce778710018d27b85/images/ilya.webp?v=2024-10-03T22%3A10%3A01.144Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/66b79a6ce778710018d27b85/images/ilya.webp?v=2024-10-03T22%3A10%3A01.144Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/66b79a6ce778710018d27b85/images/ilya.webp?v=2024-10-03T22%3A10%3A01.144Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/66b79a6ce778710018d27b85/images/ilya.webp?v=2024-10-03T22%3A10%3A01.144Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/66b79a6ce778710018d27b85/images/ilya.webp?v=2024-10-03T22%3A10%3A01.144Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 1100px) 100vw,100vw"
					/>
					{"  "}
					<Box
						align-self="stretch"
						height="240px"
						flex-direction="column"
						justify-content="flex-start"
						align-items="flex-start"
						grid-gap="16px"
						display="flex"
						sm-height="fit-content"
						sm-align-items="center"
						sm-display="flex"
						sm-flex-direction="column"
						sm-justify-content="center"
						sm-flex-wrap="no-wrap"
					>
						{"    "}
						<Text
							align-self="stretch"
							color="#080808"
							font="600 28px Epilogue"
							line-height="32px"
							word-wrap="break-word"
							margin="0px 0px 0px 0px"
							sm-text-align="center"
							sm-font="600 21px/28px Epilogue"
						>
							Ilya Kazansky
						</Text>
						{"    "}
						<Text
							color="#EB1E1E"
							font="500 16px Epilogue"
							text-transform="uppercase"
							line-height="20px"
							letter-spacing="0.80px"
							word-wrap="break-word"
							margin="0px 0px 0px 0px"
							sm-font="500 13px Epilogue"
						>
							CEO / Co-founder
						</Text>
						{"    "}
						<Text
							align-self="stretch"
							color="#212121"
							font="400 17px Epilogue"
							line-height="26px"
							word-wrap="break-word"
							margin="0px 0px 0px 0px"
							sm-text-align="center"
							sm-font="400 15px/24px Epilogue"
						>
							15+ years experience as tech entrepreneur, senior executive, board director and advisor across IoT, AI, ML and Big Data products across several verticals. Founded, funded and built multiple teams, products and companies, 3 exits.
						</Text>
						{"  "}
					</Box>
				</Box>
				<Box
					width="450px"
					height="536px"
					padding="32px"
					border="1px #B1B1B1 solid"
					flex-direction="column"
					justify-content="flex-start"
					align-items="flex-start"
					grid-gap="32px"
					display="inline-flex"
					sm-width="100%"
					sm-align-items="center"
					sm-display="flex"
					sm-flex-direction="column"
					sm-justify-content="space-between"
					sm-flex-wrap="no-wrap"
					sm-height="min-content"
				>
					{"  "}
					<Image
						width="200px"
						height="200px"
						border-radius="9999px"
						src="https://uploads.quarkly.io/66b79a6ce778710018d27b85/images/peter.webp?v=2024-10-03T22:10:20.883Z"
						srcSet="https://smartuploads.quarkly.io/66b79a6ce778710018d27b85/images/peter.webp?v=2024-10-03T22%3A10%3A20.883Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/66b79a6ce778710018d27b85/images/peter.webp?v=2024-10-03T22%3A10%3A20.883Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/66b79a6ce778710018d27b85/images/peter.webp?v=2024-10-03T22%3A10%3A20.883Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/66b79a6ce778710018d27b85/images/peter.webp?v=2024-10-03T22%3A10%3A20.883Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/66b79a6ce778710018d27b85/images/peter.webp?v=2024-10-03T22%3A10%3A20.883Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/66b79a6ce778710018d27b85/images/peter.webp?v=2024-10-03T22%3A10%3A20.883Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/66b79a6ce778710018d27b85/images/peter.webp?v=2024-10-03T22%3A10%3A20.883Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 1100px) 100vw,100vw"
					/>
					{"  "}
					<Box
						align-self="stretch"
						height="240px"
						flex-direction="column"
						justify-content="flex-start"
						align-items="flex-start"
						grid-gap="16px"
						display="flex"
						sm-height="fit-content"
						sm-align-items="center"
						sm-display="flex"
						sm-flex-direction="column"
						sm-justify-content="center"
						sm-flex-wrap="no-wrap"
					>
						{"    "}
						<Text
							align-self="stretch"
							color="#080808"
							font="600 28px Epilogue"
							line-height="32px"
							word-wrap="break-word"
							margin="0px 0px 0px 0px"
							sm-text-align="center"
							sm-font="600 21px/28px Epilogue"
						>
							Peter Kazansky
						</Text>
						{"    "}
						<Text
							color="#EB1E1E"
							font="500 16px Epilogue"
							text-transform="uppercase"
							line-height="20px"
							letter-spacing="0.80px"
							word-wrap="break-word"
							margin="0px 0px 0px 0px"
							sm-font="500 13px Epilogue"
						>
							Chief Science Officer / Co-founder
						</Text>
						{"    "}
						<Text
							align-self="stretch"
							color="#212121"
							font="400 17px Epilogue"
							line-height="26px"
							word-wrap="break-word"
							margin="0px 0px 0px 0px"
							sm-text-align="center"
							sm-font="400 15px/24px Epilogue"
						>
							A distinguished professor at the forefront of a technological revolution with his pioneering advancements in photonics and optoelectronics. Peter has over 40 years experience in leading research teams. Inventor of 5D Glass Memory.
						</Text>
						{"  "}
					</Box>
				</Box>
				<Box
					width="450px"
					height="536px"
					padding="32px"
					border="1px #B1B1B1 solid"
					flex-direction="column"
					justify-content="flex-start"
					align-items="flex-start"
					grid-gap="32px"
					display="inline-flex"
					sm-width="100%"
					sm-align-items="center"
					sm-display="flex"
					sm-flex-direction="column"
					sm-justify-content="space-between"
					sm-flex-wrap="no-wrap"
					sm-height="min-content"
				>
					{"  "}
					<Image
						width="200px"
						height="200px"
						border-radius="9999px"
						src="https://uploads.quarkly.io/66b79a6ce778710018d27b85/images/Yuriy%20Svirko.png?v=2024-08-28T16:43:51.307Z"
						srcSet="https://smartuploads.quarkly.io/66b79a6ce778710018d27b85/images/Yuriy%20Svirko.png?v=2024-08-28T16%3A43%3A51.307Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/66b79a6ce778710018d27b85/images/Yuriy%20Svirko.png?v=2024-08-28T16%3A43%3A51.307Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/66b79a6ce778710018d27b85/images/Yuriy%20Svirko.png?v=2024-08-28T16%3A43%3A51.307Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/66b79a6ce778710018d27b85/images/Yuriy%20Svirko.png?v=2024-08-28T16%3A43%3A51.307Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/66b79a6ce778710018d27b85/images/Yuriy%20Svirko.png?v=2024-08-28T16%3A43%3A51.307Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/66b79a6ce778710018d27b85/images/Yuriy%20Svirko.png?v=2024-08-28T16%3A43%3A51.307Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/66b79a6ce778710018d27b85/images/Yuriy%20Svirko.png?v=2024-08-28T16%3A43%3A51.307Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 1100px) 100vw,100vw"
					/>
					{"  "}
					<Box
						align-self="stretch"
						height="240px"
						flex-direction="column"
						justify-content="flex-start"
						align-items="flex-start"
						grid-gap="16px"
						display="flex"
						sm-height="fit-content"
						sm-align-items="center"
						sm-display="flex"
						sm-flex-direction="column"
						sm-justify-content="center"
						sm-flex-wrap="no-wrap"
					>
						{"    "}
						<Text
							align-self="stretch"
							color="#080808"
							font="600 28px Epilogue"
							line-height="32px"
							word-wrap="break-word"
							margin="0px 0px 0px 0px"
							sm-text-align="center"
							sm-font="600 21px/28px Epilogue"
						>
							Yuriy Svirko
						</Text>
						{"    "}
						<Text
							color="#EB1E1E"
							font="500 16px Epilogue"
							text-transform="uppercase"
							line-height="20px"
							letter-spacing="0.80px"
							word-wrap="break-word"
							margin="0px 0px 0px 0px"
							sm-font="500 13px Epilogue"
						>
							R&D Director
						</Text>
						{"    "}
						<Text
							align-self="stretch"
							color="#212121"
							font="400 17px Epilogue"
							line-height="26px"
							word-wrap="break-word"
							margin="0px 0px 0px 0px"
							sm-text-align="center"
							sm-font="400 15px/24px Epilogue"
						>
							A renowned physicist and leading expert in nonlinear optics and nanophotonics. With extensive experience in the development of advanced optical technologies, his research spans femtosecond laser manipulation, quantum photonics, and the interaction of light with nanostructured materials.
						</Text>
						{"  "}
					</Box>
				</Box>
				<Box
					width="450px"
					height="536px"
					padding="32px"
					border="1px #B1B1B1 solid"
					flex-direction="column"
					justify-content="flex-start"
					align-items="flex-start"
					grid-gap="32px"
					display="inline-flex"
					sm-width="100%"
					sm-align-items="center"
					sm-display="flex"
					sm-flex-direction="column"
					sm-justify-content="space-between"
					sm-flex-wrap="no-wrap"
					sm-height="min-content"
				>
					{"  "}
					<Image
						width="200px"
						height="200px"
						border-radius="9999px"
						src="https://uploads.quarkly.io/66b79a6ce778710018d27b85/images/igor.webp?v=2024-10-01T20:50:46.754Z"
						srcSet="https://smartuploads.quarkly.io/66b79a6ce778710018d27b85/images/igor.webp?v=2024-10-01T20%3A50%3A46.754Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/66b79a6ce778710018d27b85/images/igor.webp?v=2024-10-01T20%3A50%3A46.754Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/66b79a6ce778710018d27b85/images/igor.webp?v=2024-10-01T20%3A50%3A46.754Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/66b79a6ce778710018d27b85/images/igor.webp?v=2024-10-01T20%3A50%3A46.754Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/66b79a6ce778710018d27b85/images/igor.webp?v=2024-10-01T20%3A50%3A46.754Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/66b79a6ce778710018d27b85/images/igor.webp?v=2024-10-01T20%3A50%3A46.754Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/66b79a6ce778710018d27b85/images/igor.webp?v=2024-10-01T20%3A50%3A46.754Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 1100px) 100vw,100vw"
					/>
					{"  "}
					<Box
						align-self="stretch"
						height="240px"
						flex-direction="column"
						justify-content="flex-start"
						align-items="flex-start"
						grid-gap="16px"
						display="flex"
						sm-height="fit-content"
						sm-align-items="center"
						sm-display="flex"
						sm-flex-direction="column"
						sm-justify-content="center"
						sm-flex-wrap="no-wrap"
					>
						{"    "}
						<Text
							align-self="stretch"
							color="#080808"
							font="600 28px Epilogue"
							line-height="32px"
							word-wrap="break-word"
							margin="0px 0px 0px 0px"
							sm-text-align="center"
							sm-font="600 21px/28px Epilogue"
						>
							Igor Konoplyasty
						</Text>
						{"    "}
						<Text
							color="#EB1E1E"
							font="500 16px Epilogue"
							text-transform="uppercase"
							line-height="20px"
							letter-spacing="0.80px"
							word-wrap="break-word"
							margin="0px 0px 0px 0px"
							sm-font="500 13px Epilogue"
						>
							Operations Director
						</Text>
						{"    "}
						<Text
							align-self="stretch"
							color="#212121"
							font="400 17px Epilogue"
							line-height="26px"
							word-wrap="break-word"
							margin="0px 0px 0px 0px"
							sm-text-align="center"
							sm-font="400 15px/24px Epilogue"
						>
							Bringing over 15 years of leadership and financial expertise to the team. As a seasoned professional with extensive experience in managing and scaling digital technology companies, Igor has successfully held CEO roles in several fast-growing firms.
						</Text>
						{"  "}
					</Box>
				</Box>
				<Box
					width="450px"
					height="536px"
					padding="32px"
					border="1px #B1B1B1 solid"
					flex-direction="column"
					justify-content="flex-start"
					align-items="flex-start"
					grid-gap="32px"
					display="inline-flex"
					sm-width="100%"
					sm-align-items="center"
					sm-display="flex"
					sm-flex-direction="column"
					sm-justify-content="space-between"
					sm-flex-wrap="no-wrap"
					sm-height="min-content"
				>
					{"  "}
					<Image
						width="200px"
						height="200px"
						border-radius="9999px"
						src="https://uploads.quarkly.io/66b79a6ce778710018d27b85/images/Galina.png?v=2024-09-18T22:34:26.136Z"
						srcSet="https://smartuploads.quarkly.io/66b79a6ce778710018d27b85/images/Galina.png?v=2024-09-18T22%3A34%3A26.136Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/66b79a6ce778710018d27b85/images/Galina.png?v=2024-09-18T22%3A34%3A26.136Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/66b79a6ce778710018d27b85/images/Galina.png?v=2024-09-18T22%3A34%3A26.136Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/66b79a6ce778710018d27b85/images/Galina.png?v=2024-09-18T22%3A34%3A26.136Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/66b79a6ce778710018d27b85/images/Galina.png?v=2024-09-18T22%3A34%3A26.136Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/66b79a6ce778710018d27b85/images/Galina.png?v=2024-09-18T22%3A34%3A26.136Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/66b79a6ce778710018d27b85/images/Galina.png?v=2024-09-18T22%3A34%3A26.136Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 1100px) 100vw,100vw"
					/>
					{"  "}
					<Box
						align-self="stretch"
						height="240px"
						flex-direction="column"
						justify-content="flex-start"
						align-items="flex-start"
						grid-gap="16px"
						display="flex"
						sm-height="fit-content"
						sm-align-items="center"
						sm-display="flex"
						sm-flex-direction="column"
						sm-justify-content="center"
						sm-flex-wrap="no-wrap"
					>
						{"    "}
						<Text
							align-self="stretch"
							color="#080808"
							font="600 28px Epilogue"
							line-height="32px"
							word-wrap="break-word"
							margin="0px 0px 0px 0px"
							sm-text-align="center"
							sm-font="600 21px/28px Epilogue"
						>
							Galina Rakhletsova
						</Text>
						{"    "}
						<Text
							color="#EB1E1E"
							font="500 16px Epilogue"
							text-transform="uppercase"
							line-height="20px"
							letter-spacing="0.80px"
							word-wrap="break-word"
							margin="0px 0px 0px 0px"
							sm-font="500 13px Epilogue"
						>
							Finance & Accounts
							<br />
						</Text>
						{"    "}
						<Text
							align-self="stretch"
							color="#212121"
							font="400 17px Epilogue"
							line-height="26px"
							word-wrap="break-word"
							margin="0px 0px 0px 0px"
							sm-text-align="center"
							sm-font="400 15px/24px Epilogue"
						>
							Leading the accounts team at SPhotonix, Galina brings a wealth of expertise in financial management, budgeting, and corporate accounting. With a proven track record of managing complex financial operations, Galina ensures the company maintains fiscal discipline and operational transparency.{" "}
						</Text>
						{"  "}
					</Box>
				</Box>
				<Box
					width="450px"
					height="536px"
					padding="32px"
					border="1px #B1B1B1 solid"
					flex-direction="column"
					justify-content="flex-start"
					align-items="flex-start"
					grid-gap="32px"
					display="inline-flex"
					sm-width="100%"
					sm-align-items="center"
					sm-display="flex"
					sm-flex-direction="column"
					sm-justify-content="space-between"
					sm-flex-wrap="no-wrap"
					sm-height="min-content"
				>
					{"  "}
					<Image
						width="200px"
						height="200px"
						border-radius="9999px"
						src="https://uploads.quarkly.io/66b79a6ce778710018d27b85/images/nova.webp?v=2024-10-03T22:10:35.143Z"
						srcSet="https://smartuploads.quarkly.io/66b79a6ce778710018d27b85/images/nova.webp?v=2024-10-03T22%3A10%3A35.143Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/66b79a6ce778710018d27b85/images/nova.webp?v=2024-10-03T22%3A10%3A35.143Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/66b79a6ce778710018d27b85/images/nova.webp?v=2024-10-03T22%3A10%3A35.143Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/66b79a6ce778710018d27b85/images/nova.webp?v=2024-10-03T22%3A10%3A35.143Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/66b79a6ce778710018d27b85/images/nova.webp?v=2024-10-03T22%3A10%3A35.143Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/66b79a6ce778710018d27b85/images/nova.webp?v=2024-10-03T22%3A10%3A35.143Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/66b79a6ce778710018d27b85/images/nova.webp?v=2024-10-03T22%3A10%3A35.143Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 1100px) 100vw,100vw"
					/>
					{"  "}
					<Box
						align-self="stretch"
						height="240px"
						flex-direction="column"
						justify-content="flex-start"
						align-items="flex-start"
						grid-gap="16px"
						display="flex"
						sm-height="fit-content"
						sm-align-items="center"
						sm-display="flex"
						sm-flex-direction="column"
						sm-justify-content="center"
						sm-flex-wrap="no-wrap"
					>
						{"    "}
						<Text
							align-self="stretch"
							color="#080808"
							font="600 28px Epilogue"
							line-height="32px"
							word-wrap="break-word"
							margin="0px 0px 0px 0px"
							sm-text-align="center"
							sm-font="600 21px/28px Epilogue"
						>
							Nova Spivack
						</Text>
						{"    "}
						<Text
							color="#EB1E1E"
							font="500 16px Epilogue"
							text-transform="uppercase"
							line-height="20px"
							letter-spacing="0.80px"
							word-wrap="break-word"
							margin="0px 0px 0px 0px"
							sm-font="500 13px Epilogue"
						>
							Venture Advisor
						</Text>
						{"    "}
						<Text
							align-self="stretch"
							color="#212121"
							font="400 17px Epilogue"
							line-height="26px"
							word-wrap="break-word"
							margin="0px 0px 0px 0px"
							sm-text-align="center"
							sm-font="400 15px/24px Epilogue"
						>
							A visionary entrepreneur with over two decades at the helm of tech innovation in AI, big data, and beyond. Known for his role in multiple IPOs and acquisitions by tech giants, his work continues to impact the tech landscape deeply.
						</Text>
						{"  "}
					</Box>
				</Box>
				<Box min-width="100px" min-height="100px" lg-width="450px">
					<Box
						width="450px"
						height="536px"
						padding="32px"
						border="1px #B1B1B1 solid"
						flex-direction="column"
						justify-content="flex-start"
						align-items="flex-start"
						grid-gap="32px"
						display="inline-flex"
						sm-width="100%"
						sm-align-items="center"
						sm-display="flex"
						sm-flex-direction="column"
						sm-justify-content="space-between"
						sm-flex-wrap="no-wrap"
						sm-height="min-content"
					>
						{"  "}
						<Image
							width="200px"
							height="200px"
							border-radius="9999px"
							src="https://uploads.quarkly.io/66b79a6ce778710018d27b85/images/michael.webp?v=2024-10-01T21:52:21.066Z"
							srcSet="https://smartuploads.quarkly.io/66b79a6ce778710018d27b85/images/michael.webp?v=2024-10-01T21%3A52%3A21.066Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/66b79a6ce778710018d27b85/images/michael.webp?v=2024-10-01T21%3A52%3A21.066Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/66b79a6ce778710018d27b85/images/michael.webp?v=2024-10-01T21%3A52%3A21.066Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/66b79a6ce778710018d27b85/images/michael.webp?v=2024-10-01T21%3A52%3A21.066Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/66b79a6ce778710018d27b85/images/michael.webp?v=2024-10-01T21%3A52%3A21.066Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/66b79a6ce778710018d27b85/images/michael.webp?v=2024-10-01T21%3A52%3A21.066Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/66b79a6ce778710018d27b85/images/michael.webp?v=2024-10-01T21%3A52%3A21.066Z&quality=85&w=3200 3200w"
							sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 1100px) 100vw,100vw"
						/>
						{"  "}
						<Box
							align-self="stretch"
							height="240px"
							flex-direction="column"
							justify-content="flex-start"
							align-items="flex-start"
							grid-gap="16px"
							display="flex"
							sm-height="fit-content"
							sm-align-items="center"
							sm-display="flex"
							sm-flex-direction="column"
							sm-justify-content="center"
							sm-flex-wrap="no-wrap"
						>
							{"    "}
							<Text
								align-self="stretch"
								color="#080808"
								font="600 28px Epilogue"
								line-height="32px"
								word-wrap="break-word"
								margin="0px 0px 0px 0px"
								sm-text-align="center"
								sm-font="600 21px/28px Epilogue"
							>
								Michael Shribak
							</Text>
							{"    "}
							<Text
								color="#EB1E1E"
								font="500 16px Epilogue"
								text-transform="uppercase"
								line-height="20px"
								letter-spacing="0.80px"
								word-wrap="break-word"
								margin="0px 0px 0px 0px"
								sm-font="500 13px Epilogue"
							>
								Scientific Advisor
							</Text>
							{"    "}
							<Text
								align-self="stretch"
								color="#212121"
								font="400 17px Epilogue"
								line-height="26px"
								word-wrap="break-word"
								margin="0px 0px 0px 0px"
								sm-text-align="center"
								sm-font="400 15px/24px Epilogue"
							>
								An innovator in microscopy and imaging, has revolutionized nanoscale visualization with his creation of advanced imaging techniques. Ph.D. in Optics and decades of research. Leading research team at the University of Chicago.{" "}
							</Text>
							{"  "}
						</Box>
					</Box>
				</Box>
			</Box>
			<Box
				width="1348px"
				height="1px"
				background="#B1B1B1"
				align-items="center"
				flex-direction="column"
				justify-content="center"
				flex-wrap="no-wrap"
				sm-width="100%"
			/>
		</Box>
		<Components.FooterWithForm />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"66b79a6ce778710018d27b83"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}\n\nbody  {\nzoom: 0.85;\n}"}
			</style>
			<script async={true} src={"https://www.googletagmanager.com/gtag/js?id=G-GFK1N3P5RL"} place={"endOfBody"} rawKey={"670146df6aba74b20ebdd83f"} />
			<script place={"endOfBody"} rawKey={"670147228b237590430d8fce"}>
				{"  window.dataLayer = window.dataLayer || [];\n  function gtag(){dataLayer.push(arguments);}\n  gtag('js', new Date());\n\n  gtag('config', 'G-GFK1N3P5RL');"}
			</script>
		</RawHtml>
	</Theme>;
});